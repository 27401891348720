<template>
  <div class="px-5 max-width">
    <v-card rounded="lg" class="shadow_card">
      <v-card-title v-if="$slots.title">
        <slot name="title" />
      </v-card-title>
      <v-card-subtitle v-if="$slots.subtitle">
        <slot name="subtitle" />
      </v-card-subtitle>
      <v-card-text v-if="$slots.text">
        <slot name="text" />
      </v-card-text>
      <v-divider v-if="($slots.title || $slots.subtitle || $slots.text) && $slots.actions" />
      <v-card-actions v-if="$slots.actions" class="px-4">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script setup lang="ts"></script>

<style>
.max-width {
  max-width: 700px;
  width: 100%;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.theme--light.v-divider {
  border-color: var(--grey400);
}
</style>
